

* { box-sizing:border-box; }


/* form starting stylings ------------------------------- */
.group 			  { 
  position:relative; 
}
.input 				{
  font-size:18px;
  padding:10px 10px 10px 5px;
  display:block;
  width:350px;
  border:none;
  border-bottom:3px solid green;
}


input:focus 		{ outline:none; }

/* LABEL ======================================= */
.label 				 {
  color: #757575; 
  font-size:18px;
  font-weight:normal;
  position:absolute;
  pointer-events:none;
  left:5px;
  top:10px;
  transition:0.2s ease all; 
  -moz-transition:0.2s ease all; 
  -webkit-transition:0.2s ease all;
}

/* active state */
input:focus ~ label, input:valid ~ label 		{
  top:-20px;
  font-size:14px;
  color:#A69FF6;
}

/* BOTTOM BARS ================================= */
.bar 	{ position:relative; display:block; width:350px; }
.bar:before, .bar:after 	{
  content:'';
  height:2px; 
  width:0;
  bottom:px; 
  position:absolute;
  background:#A69FF6; 
  transition:0.2s ease all; 
  -moz-transition:0.2s ease all; 
  -webkit-transition:0.5s ease all;
}
.bar:before {
  left:50%;
}
.bar:after {
  right:50%; 
}

/* active state */
input:focus ~ .bar:before, input:focus ~ .bar:after {
  width:50%;
}

/* HIGHLIGHTER ================================== */
.highlight {
  position:absolute;
  height:60%; 
  width:100px; 
  top:25%; 
  left:0;
  pointer-events:none;
  opacity:0.5;
}

/* active state */
input:focus ~ .highlight {
  -webkit-animation:inputHighlighter 0.3s ease;
  -moz-animation:inputHighlighter 0.3s ease;
  animation:inputHighlighter 0.3s ease;
}

/* ANIMATIONS ================ */
@-webkit-keyframes inputHighlighter {
	from { background:#5264AE; }
  to 	{ width:0; background:transparent; }
}
@-moz-keyframes inputHighlighter {
	from { background:#5264AE; }
  to 	{ width:0; background:transparent; }
}
@keyframes inputHighlighter {
	from { background:#5264AE; }
  to 	{ width:0; background:transparent; }
}