@tailwind base;
@tailwind components;
@tailwind utilities;


.main{
  background-color: #ffffff;
}


.active {
  color: white;
}

body::-webkit-scrollbar {
  display: none;
}

.suggestions::-webkit-scrollbar,
.rightsidebar::-webkit-scrollbar {
  width: 7px;
  background: transparent;
}

.suggestions::-webkit-scrollbar-thumb,
.rightsidebar::-webkit-scrollbar-thumb {
  background-color: #93c5fd;
  border-radius: 10px;
}

.btn {
  border-radius: 50%;
  display: grid;
  place-items: center;
  padding: 0;
  border: 3px solid red;
  height: 100px;
  width: 100px;
  overflow: hidden;
  background-color: transparent;
}

.btn:hover{
  background-color: rgb(201, 196, 196);
  border:0px
}

.circleCard{
  aspect-ratio: 1;
  height: 55px;
  width: 345px;
}

.circleCard:hover{
  background-color: rgb(201, 196, 196);
}

.success {
  border-color: #04AA6D;
  color: green;
}

.success:hover {
  background-color: #04AA6D;
  color: white;
}

.wrapper{
  max-height: 200px;
  border: 0px solid black;
  overflow-x: auto;
  background-color: transparent;
}

.wrapper .item{
  flex-shrink: 0;
  border-radius: 50%;
  display: grid;
  place-items: center;
  padding: 0;
  border: 1px solid #587D71;
  height: 56px;
  width: 56px;
  background-color: transparent;
  object-fit:cover;
  overflow:hidden;
}

@media (min-width: 600px){
  .wrapper .item{
    flex-shrink: 0;
    border-radius: 50%;
    display: grid;
    place-items: center;
    padding: 0;
    border: 2px solid #8FBC8F;
    height: 100px;
    width: 100px;
    background-color: transparent;
    object-fit:cover;
    overflow:hidden;
  }
}

.item:hover{
  background-color: #80808080;
  border:0px
}

.wrapper::-webkit-scrollbar{
  width: 5px;
  height: 5px;
  background: white;
}

.wrapper::-webkit-scrollbar-thumb{
  background-color: gray;
}

.blue_bg {
  background-color: #191A21;
  font-family:'Inter', 'Helvetica Neue';
}

.user_bg {
  background-color: #1E202C;
}

.interFont {
  font-family: 'Inter', 'Helvetica Neue';
}

.blur-box {
  background-color: #1E1E11;
  box-shadow: 0 0 5px 10px #1E1E1E;
}

.border-gray-custom {
  border-bottom: 1px solid #292A36; 
}

.white_bg {
  background-image: linear-gradient(405deg,#171717, #f8fafc);
  color:#04AA6D;
}

.grey_bg {
  background-color: #272727;
}

.purple_bg {
  background-color: #AEA0FF;
}

.login_bg {
  background: #000000;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to bottom, #434343, #000000);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to bottom, #434343, #000000); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */  
}

.background-tint {
  filter: sepia(100%) saturate(200%) brightness(70%) hue-rotate(330deg);
}

.eventRow {
  background-color: #292A36;
}

.relative1 {
  position: relative;
  overflow: hidden;
}

.bottom-blur {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px; /* adjust the height of blur as per your requirement */
  z-index: -1; /* to make it behind the image */
}

.bottom-blur::before {
  content: "";
  display: block;
  background-image: inherit;
  filter: blur(90px); /* adjust the blur value as per your requirement */
  opacity: 60%;
}


.bItem {
  display: flex;
  z-index: 0;
}

.bItem:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  padding: 8px;
  bottom: 0;
  background: linear-gradient(to bottom, transparent 0%, #af9db3 100%);
}


.icon-list li {
  display: none;
}
.icon-list li:nth-child(-n+3) {
  display: block;
}

.nav_purple {
  background: #A69FF6;
  width: 64px;
  height: 37px;
  justify-content: center;
  align-items: center;
  transition: all 300ms ease-in-out;
}

.scroll-down navbar {
  opacity: 0;
}

.navbar {
  width: 100%;
}


.scroll-up navbar {
  border-bottom: 5px solid black;
}

.discussion_bg {
  background-color: #292A36;
}

.feed_post {
  background-color: inherit;
  overflow: hidden;
  border-radius: 15px;
  
}

.picture_16_home {
  height: 330px;
  object-fit: cover;
  width: auto;
  aspect-ratio: 16/9;
}

.picture_16_event {
  height: 340px;
  object-fit: cover;
  width: auto;
  aspect-ratio: 16/9;
}


.feed_post::after {
  content: '';
  position: absolute;
  top: 40%;
  left: 0;
  right: 0;
  padding: 0px;
  bottom: 0;
  background: linear-gradient(to bottom, transparent 0%, #191A21 100%);
}

/* 15 */
@media screen and (min-width: 800px) {
  .login-btn {
    background: white;
    border: none;
    z-index: 1;
    width: 110px;
    height: 40px;
    color: black;
    border-radius: 5px;
    padding: 8px 20px;
    cursor: pointer;
    transition: all 0.2s ease;
    position: relative;
    font-family: "Inter";
    font-size: 18px;
  }
}

@media screen and (max-width: 600px) {
  .login-btn {
    background: white;
    border: none;
    z-index: 1;
    width: 60px;
    height: 30px;
    color: black;
    border-radius: 5px;
    padding: 2px 4px;
    cursor: pointer;
    transition: all 0.1s ease;
    position: relative;
    font-size:"15px";
  }
}

.login-btn:after {
  position: absolute;
  content: "";
  width: 0;
  height: 100%;
  top: 0;
  right: 0;
  z-index: -1;
  background-color: #A69FF6;
  border-radius: 5px;
   box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
   7px 7px 20px 0px rgba(0,0,0,.1),
   4px 4px 5px 0px rgba(0,0,0,.1);
  transition: all 0.4s ease;
}
.login-btn:hover {
  color: #fff;
}
.login-btn:hover:after {
  left: 0;
  width: 100%;
}
.login-btn:active {
  top: 2px;
}

.post_time {
  color: #B9B9B9;
}

.feed_date {
  background: #333333;
  text-align: center;
  
}


@media screen and (min-width: 640px) {

  .card{
    position: relative;
    border-radius:35px;
    width:630px;
    background: radial-gradient(93.62% 405.98% at 2.8% 5.47%, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 100%), rgba(58, 47, 83, 0.6);
    backdrop-filter: blur(20px);
    background-clip: padding-box;
    border-top: 1.5px solid #856FA1;
    border-left: 1.5px solid #856FA1;
    box-shadow: inset 0 0 50px rgba(175, 175, 175, 0.15),10px 10px 10px rgba(7, 7, 7, 0.05);
  }

  .glow {
  box-shadow: -2px -2px 12px 1px #A69FF6,
              2px 2px 12px 1px #A69FF6;
  }
}